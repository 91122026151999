.lock-screen {
  background-image: url(https://webaddress.site/logo.png);
  background-size: cover;
  background-position: center;
  max-width: 800px;
  min-width: 400px;
  min-height: 600px;
  max-height: 1000px;
}

.lock-screen-controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.lock-screen-btn {
  height: 40px;
}

.login-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
}

.login-card {
  padding-top: 24px;
  margin: 20px;
  height: 400px;
  width: 600px;
}
