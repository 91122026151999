.scroll-container {
  display: flex;
  flex-direction: column;
  overflow-anchor: none;
  overflow-y: scroll;
  width: 100vw;
}

.scroll-container-y {
  display: flex;
  flex-direction: row;
  overflow-anchor: none;
  overflow-y: scroll;
  overflow-x: auto;
  width: 100vw;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
