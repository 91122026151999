.top-chrome {
  position: fixed;
  height: 72px;
  width: 100%;
  z-index: 1000;
}

.dev-banner {
  background: black;
  color: white;
  height: 20px;
  position: absolute;
  right: -24px;
  text-align: center;
  top: 12px;
  transform: rotate(45deg);
  width: 100px;
  z-index: 1000;
}

.bottom-chrome > .Mui-selected {
  color: #ffffff !important;
}
