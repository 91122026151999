.recipe-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px 6px;
}

.v2-recipe-card {
  width: 180px;
  min-width: 180px;
  border-radius: 12px;
}

.v2-recipe-card > img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 24px;
  height: 220px;
  object-fit: cover;
  width: 100%;
}

.v2-recipe-card > button {
  padding: 0px;
}

.recipe-card-body {
  flex-grow: 1;
}

.recipe-card-header {
  align-items: last-baseline;
  display: flex;
  min-height: 32px;
  padding: 12px;
  justify-content: space-between;
}

.recipe-modal-content {
  padding-top: 24px;
  margin: 20px;
}

.recipe-modal-tags {
  margin-top: 12px;
  margin-bottom: 12px;
}

.recipe-modal-label-text {
  font-size: 8pt;
}

.recipe-modal-fields-container {
  display: flex;
  flex-direction: column;
}

.recipe-modal-fields-row {
  display: flex;
  height: 44px;
}

.recipe-modal-fields-label {
  width: 140px;
}

.new-recipe-input {
  margin-top: 20px;
}
